import { ActionTree, GetterTree, Module, MutationTree } from 'vuex'
import { AxiosList, Data, Meta, RequestParams } from '@/modules/common/apiConfig'
import { RootState } from '@/store'
import axios from 'axios'

// Results
import {
	LabNote,
	ResultDocument,
	SectionDetails,
	SectionStates,
	StorageStates,
} from '@/modules/items/types/resultsTypes'
import {
	getConcentrationState,
	getConcentrationTopPercentValue,
	getConcentrationValue,
	getCryogenicState,
	getCryoStorageState,
	getDnaFragmentationState,
	getDnaFragmentationTopPercentValue,
	getDnaFragmentationValue,
	getIsAzoospermic,
	getMorphologyState,
	getMorphologyTopPercentValue,
	getMorphologyValue,
	getMotilityState,
	getMotilityTopPercentValue,
	getMotilityValue,
	getStorageState,
	getTmcState,
	getTmcTopPercentValue,
	getTmcValue,
	getTcValue,
	getTcState,
	getTcTopPercentValue,
	getVolumeState,
	getVolumeTopPercentValue,
	getVolumeValue,
} from '@/modules/items/utils/resultsHelpers'
import { Item, ItemAttributes, OnBoardingRequest } from '@/modules/items/types/itemTypes'
import { StiStates } from '@/modules/items/types/stiResultsTypes'
import { getStiState, getStiStorageState } from '@/modules/items/utils/stiResultsHelpers'
import { DeliveryCarriers } from '@/modules/kitRegistrations/enums/kitRegistrationEnums'
import { itemsTestingMutations } from '@/modules/testing/mutations/itemsTestingMutations'
import { itemsTestingPanelGetters } from '@/modules/testing/getters/itemsTestingGetters'

export type State = {
	items: Item[]
	itemsMeta: Meta
	itemsLoading: boolean
	testing: boolean
	disabled_pdd_dates: string[]
}

export const state = (): State => ({
	items: [],
	itemsMeta: {
		from: 1,
		to: 1,
		current_page: 1,
	},
	itemsLoading: false,
	testing: false,
	disabled_pdd_dates: [],
})

const mutations: MutationTree<State> = {
	setItems(state: State, items: Item[]) {
		state.items = items
	},
	setItemsMeta(state: State, itemsMeta: Meta) {
		state.itemsMeta = itemsMeta
	},
	setItemsLoading(state: State, loading: boolean) {
		state.itemsLoading = loading
	},
	updateItem(state: State, item: Item) {
		const index = state.items.findIndex(i => i.id === item.id)
		if (index !== -1) {
			state.items[index] = item
		}
	},
	setLabNotes(state: State, { item, value }: { item: Item; value: Array<Data<LabNote>> }) {
		const analysis = state.items.find(x => x.id === item.id)

		const resultDocument = (analysis as Item)?.relationships?.resultDocuments?.[0]

		if (!resultDocument) {
			return
		}

		resultDocument.attributes['lab_notes'] = value
	},
	setDisabledPddDates(state: State, disabledDates: []) {
		state.disabled_pdd_dates = disabledDates || []
	},
	...itemsTestingMutations,
}

const actions: ActionTree<State, RootState> = {
	async getTestingResult({ commit, state }, token: string): Promise<ResultDocument[] | void> {
		try {
			commit('setItemsLoading', true)

			const itemsData: ResultDocument = await axios.get(`/public-results/${token}`)

			let { data } = itemsData

			commit('setItems', data)
			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
		} finally {
			commit('setItemsLoading', false)
		}
	},
	async getItems(
		{ commit, state },
		params = { related: 'order' } as RequestParams,
	): Promise<Item[] | void> {
		try {
			commit('setItemsLoading', true)

			const itemsData: AxiosList<ItemAttributes> = await axios.get('/restify/items', {
				params: {
					...params,
					displayable: true
				},
			})

			let { data, meta } = itemsData

			if (params?.page > 1) {
				data = state.items.concat(data)
			}
			commit('setItems', data)
			commit('setItemsMeta', meta)

			return data
		} catch (err: any) {
			if (err.handled) {
				return
			}
		} finally {
			commit('setItemsLoading', false)
		}
	},
	async getItemById({ commit }, id) {
		const { data }: { data: Data<ItemAttributes> } = await axios.get(`/restify/items/${id}`, {
			params: {
				related: 'package,documents,order,product',
			},
		})
		commit('updateItem', data)
		return data
	},
	async updateItemShippingAddress({ dispatch }, data): Promise<any> {
		await axios.post(`/restify/items/${data.id}/actions?action=update-shipping-address`, data)
		await dispatch('getItemById', data.id)
	},
	async updateItemOnBoarding({ dispatch }, data: OnBoardingRequest): Promise<any> {
		await axios.post(`/onboarding/item/${data.item_uuid}`, data)
		await dispatch('auth/getProfile', null, { root: true })
	},
	async getDisabledPddDates({ commit, getters }): Promise<any> {
		const { disabled_pdd_dates } = (
			await axios.get(`/restify/items/${getters?.currentAnalysis?.id}/getters/disabled-pdd-dates`)
		)?.data
		commit('setDisabledPddDates', disabled_pdd_dates)
	},
	async getCurrentResultDocumentLabNotes({ commit, getters }) {
		const currentResult = getters.currentResults as ResultDocument

		const params = {
			related: 'activeLabNotes',
		}

		const { data } = await axios.get(`/restify/result-documents/${currentResult.id}`, {
			params,
		})

		commit('setLabNotes', {
			item: getters.currentAnalysis,
			value: data.relationships?.activeLabNotes || [],
		})
	},
}

const getters: GetterTree<State, RootState> = {
	hasOnlySupplements(state): boolean {
		if (state.items.length === 0) {
			return true
		}
		return state.items.every(item => item.attributes.category === 'Supplement')
	},
	hasOnlyConsultations(state): boolean {
		if (state.items.length === 0) {
			return true
		}
		return state.items.every(item => item.attributes.is_consultation)
	},
	needsMdiReview(state): boolean {
		if (state.items.length === 0) {
			return false
		}

		return state.items.some(function (item: Item) {
			return item.attributes.needs_mdi_validation
		})
	},
	currentAnalysis(state, getters, rootState): Item | undefined {
		return state.items.find(item => item.attributes.uuid === rootState.route.query.id)
	},
	isUPSDeliveryCarrier(state, getters): boolean {
		return (getters.currentAnalysis as Item)?.attributes.delivery_carrier === DeliveryCarriers.UPS
	},
	currentResults(state, getters): ResultDocument | undefined {
		return (getters.currentAnalysis as Item)?.relationships?.resultDocuments?.[0]
			?.attributes as ResultDocument
	},
	// Common
	currentAnalysis_isAzoospermic(state, getters): boolean {
		return getIsAzoospermic(getters.currentResults as ResultDocument)
	},
	currentAnalysis_storageState(state, getters): StorageStates {
		return getStorageState(getters.currentAnalysis as Item)
	},
	// Total count
	currentAnalysis_tcDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		return {
			value: getTcValue(resultDocument),
			state: getTcState(resultDocument),
			topPercentValue: getTcTopPercentValue(resultDocument),
			wasAnalysed: true,
		}
	},
	// TMC
	currentAnalysis_tmcDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		return {
			value: getTmcValue(resultDocument),
			state: getTmcState(resultDocument),
			topPercentValue: getTmcTopPercentValue(resultDocument),
			wasAnalysed: true,
		}
	},
	// Volume
	currentAnalysis_volumeDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		return {
			value: getVolumeValue(resultDocument),
			state: getVolumeState(resultDocument),
			topPercentValue: getVolumeTopPercentValue(resultDocument),
			wasAnalysed: true,
		}
	},
	// Concentration
	currentAnalysis_concentrationDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		return {
			value: getConcentrationValue(resultDocument),
			state: getConcentrationState(resultDocument),
			topPercentValue: getConcentrationTopPercentValue(resultDocument),
			wasAnalysed: true,
		}
	},
	// Motility
	currentAnalysis_motilityDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		const sectionState = getMotilityState(resultDocument)
		const wasAnalysed = ![SectionStates.Azoospermic].includes(sectionState)

		return {
			value: getMotilityValue(resultDocument),
			state: sectionState,
			topPercentValue: getMotilityTopPercentValue(resultDocument),
			wasAnalysed,
		}
	},
	// Morphology
	currentAnalysis_morphologyDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		const sectionState = getMorphologyState(resultDocument)
		const wasAnalysed = ![SectionStates.Azoospermic, SectionStates.LowConcentration].includes(
			sectionState,
		)

		return {
			value: getMorphologyValue(resultDocument),
			state: sectionState,
			topPercentValue: getMorphologyTopPercentValue(resultDocument),
			wasAnalysed,
		}
	},
	// DNA Fragmentation
	currentAnalysis_dnaFragmentationDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		const sectionState = getDnaFragmentationState(resultDocument)
		const wasAnalysed = ![SectionStates.Azoospermic, SectionStates.LowConcentration].includes(
			sectionState,
		)

		return {
			value: getDnaFragmentationValue(resultDocument),
			state: sectionState,
			topPercentValue: getDnaFragmentationTopPercentValue(resultDocument),
			wasAnalysed,
		}
	},
	// Cryogenic storage
	currentAnalysis_cryogenicDetails(state, getters): SectionDetails {
		const resultDocument = getters.currentResults as ResultDocument
		const currentAnalysis = getters.currentAnalysis as Item
		return {
			state: getCryogenicState(resultDocument, currentAnalysis),
			wasAnalysed: true, // Not applicable
			value: 0, // Not applicable
		}
	},
	cryoSection_storageState(state, getters): StorageStates {
		return getCryoStorageState(getters.currentAnalysis as Item)
	},
	// STI
	currentStiResults(state, getters): ResultDocument | undefined {
		return (getters.currentAnalysis as Item)?.relationships?.stiResult?.attributes
			?.results as ResultDocument
	},
	currentSti_state(state, getters): StiStates {
		const currentAnalysis = getters.currentAnalysis as Item
		return getStiState(currentAnalysis)
	},
	sti_storageState(state, getters): StorageStates {
		const currentAnalysis = getters.currentAnalysis as Item
		return getStiStorageState(currentAnalysis)
	},
	...itemsTestingPanelGetters,
}

const module: Module<State, RootState> = {
	namespaced: true,
	state,
	mutations,
	actions,
	getters,
}

export default module
